import {
  Link,
  Nil,
  WithId,
  WithLinks,
  WithMultiLangName,
  getRelation,
  hasRelation,
} from '@model';

export const TRANSACTION_START_RELATION = 'transaction:start';

export interface LocationEvseContract
  extends WithId,
    WithMultiLangName,
    WithLinks {
  fixedFee?: number;
  idleFee?: number;
  pricePerKwh?: number;
  pricePerMinute?: number;
  idleFeeApplicableAfterMinutes?: number;
  currencyCode: string;
  hasAuthMeanAssigned: boolean;
  isEvseAvailable: boolean;
  isScheduled: boolean;
}

export function getTransactionStartLink(
  contract: LocationEvseContract,
): Link | Nil {
  return getRelation(contract, TRANSACTION_START_RELATION);
}

export function hasTransactionStartLink(
  contract: LocationEvseContract,
): boolean {
  return hasRelation(contract, TRANSACTION_START_RELATION);
}
