import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {
  LocationEvsesApiService,
  LocationsApiService,
} from '@api/evd/locations';
import { isError } from '@core/error';
import { DASHBOARD_NAV_ITEM_ID } from '@core/evd/const';
import { EVSE_STATUS_MAP_STATUS } from '@core/evd/utils/evse-status.utils';
import { isNotNil } from '@core/is-not-nil';
import { filterNil } from '@core/rxjs/filter-nil';
import { WebsocketService } from '@core/websocket';
import { environment } from '@env/evd/environment';
import { MESSAGES } from '@i18n/evd';
import { MapContainerDataSourceWithDistance } from '@layout/map-container';
import { Nil, getMandatorySelfLink } from '@model';
import { ListEmspContract } from '@model/evd/emsp';
import {
  ListLocationEvse,
  Location,
  LocationEvse,
  LocationEvseContract,
  LocationEvseContractTransactionStartStatus,
  LocationEvseStatusUpdate,
  getLocationEvsesLink,
} from '@model/evd/locations';
import { getMandatoryLocationsLink } from '@model/evd/users';
import { CurrentUserService } from '@store/evd/current-user';
import { LayoutService } from '@store/evd/layout';
import { DialogPosition, DialogService } from '@ui/dialog';
import { NotificationService } from '@ui/notification';
import { replace } from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  delay,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

import { DashboardPageFiltersDialogComponent } from './filters-dialog/dashboard-page-filters-dialog.component';
import { DashboardPageFiltersDialogData } from './filters-dialog/dashboard-page-filters-dialog.types';

@Injectable()
export class DashboardPageService {
  public constructor(
    private layoutService: LayoutService,
    private locationsApiService: LocationsApiService,
    private locationEvseApiService: LocationEvsesApiService,
    private websocketService: WebsocketService,
    private dialogService: DialogService,
    private currentUserService: CurrentUserService,
    private notificationService: NotificationService,
  ) {}

  private destroySubject$ = new Subject<void>();
  private locationSubject$ = new ReplaySubject<Location>(1);
  private evseSubject$ = new ReplaySubject<ListLocationEvse>(1);
  private loadingSubject$ = new ReplaySubject<boolean>(1);
  private openFiltersDialogSubject$ = new Subject<void>();
  private selectedContractIdsSubject$ = new BehaviorSubject<string[]>([]);
  private refreshLocationsSubject$ = new Subject<void>();
  private refreshLocationEvseSubject$ = new Subject<void>();
  private startChargingSubject$ = new Subject<{
    evse: LocationEvse;
    contract: LocationEvseContract;
  }>();

  public loading$ = this.loadingSubject$.asObservable();

  public evses$: Observable<ListLocationEvse[]> = this.locationSubject$.pipe(
    map(getLocationEvsesLink),
    switchMap((link) => {
      return this.locationEvseApiService.listWithoutPagination(link, 'evses');
    }),
    map((response) => {
      if (isError(response)) {
        return [];
      }
      return response;
    }),
    tap(() => {
      this.loadingSubject$.next(false);
    }),
  );

  public evse$: Observable<LocationEvse | Nil> = combineLatest([
    this.evseSubject$,
    this.refreshLocationEvseSubject$.pipe(startWith(undefined)),
  ]).pipe(
    map(([evse]) => {
      return getMandatorySelfLink(evse);
    }),
    switchMap((link) => {
      return this.locationEvseApiService.get(link);
    }),
    map((response) => {
      if (isError(response)) {
        return undefined;
      }
      return response;
    }),
    tap(() => {
      this.loadingSubject$.next(false);
      this.layoutService.setLoading(false);
    }),
  );

  public locationsDataSource$: Observable<
    MapContainerDataSourceWithDistance<Location>
  > = combineLatest([
    this.currentUserService.currentUserFetched$.pipe(filterNil()),
    this.selectedContractIdsSubject$,
  ]).pipe(
    map(([user, ids]) => {
      const link = getMandatoryLocationsLink(user);
      const parameters = ids.join('&driverContractIds=');
      const href = replace(link.href, '{driverContractIds}', parameters);

      return new MapContainerDataSourceWithDistance<Location>({
        api: this.locationsApiService,
        embedded: 'locations',
        link: {
          ...link,
          href,
        },
        counter: ({ evses }, status) => {
          return evses.filter((evse) => {
            return EVSE_STATUS_MAP_STATUS[evse.status] === status;
          }).length;
        },
        paginated: false,
        refresh: this.refreshLocationsSubject$.pipe(delay(1000)),
      });
    }),
    shareReplay(1),
  );

  public locations$ = this.locationsDataSource$.pipe(
    switchMap((dataSource) => {
      return dataSource.mapDataSource$;
    }),
    map((data) => {
      return data.items.map((item) => {
        return item.data;
      });
    }),
  );

  public initialize(token: string, contracts: ListEmspContract[]) {
    this.layoutService.setLocation({
      id: DASHBOARD_NAV_ITEM_ID,
      name: MESSAGES.appMenu.label.home,
      breadcrumbs: [],
    });
    this.websocketService.initialize(environment.urls.websocket, token);

    this.openFiltersDialogSubject$
      .pipe(
        withLatestFrom(this.selectedContractIdsSubject$),
        takeUntil(this.destroySubject$),
      )
      .subscribe(([_, selectedContractIds]) => {
        this.openFiltersDialog(contracts, selectedContractIds);
      });

    this.websocketService
      .on<LocationEvseStatusUpdate>('NotifyUsersOnEvseStatusChanged')
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.refreshLocationsSubject$.next();
        this.refreshLocationEvseSubject$.next();
      });

    this.startChargingSubject$
      .pipe(
        switchMap(({ contract, evse }) => {
          return this.locationEvseApiService.startTransaction(evse, contract);
        }),
        tap((response) => {
          this.transactionStartApiCallback(response);
        }),
        takeUntil(this.destroySubject$),
      )
      .subscribe();
  }

  public setLocation(location: Location): void {
    this.loadingSubject$.next(true);
    this.locationSubject$.next(location);
  }

  public setEvse(evse: ListLocationEvse): void {
    this.loadingSubject$.next(true);
    this.evseSubject$.next(evse);
  }

  public destroy() {
    this.destroySubject$.next();
    this.websocketService.destroy();
  }

  public openFilters(): void {
    this.layoutService.setLoading(true);
    this.openFiltersDialogSubject$.next();
  }

  public startCharging(
    evse: LocationEvse | Nil,
    contract: LocationEvseContract | Nil,
  ) {
    if (isNotNil(contract) && isNotNil(evse)) {
      this.layoutService.setLoading(true);
      this.startChargingSubject$.next({ evse, contract });
    }
  }

  private openFiltersDialog(
    contracts: ListEmspContract[],
    selectedContractIds: string[],
  ): void {
    this.layoutService.setLoading(false);
    this.dialogService.openDialog<DashboardPageFiltersDialogData>({
      position: DialogPosition.RightSide,
      component: new ComponentPortal(DashboardPageFiltersDialogComponent),
      data: {
        contracts,
        selectedContractIds,
      },
      onClose: (data) => {
        this.selectedContractIdsSubject$.next(data.selectedContractIds);
      },
      title: MESSAGES.dashboardPage.title.filterContracts,
    });
  }

  public transactionStartApiCallback(
    response: LocationEvseContractTransactionStartStatus | Error,
  ) {
    this.layoutService.setLoading(false);
    if (response !== LocationEvseContractTransactionStartStatus.Success) {
      this.notificationService.notifyError(
        MESSAGES.dashboardPage.transactionStartNotification.error,
      );
    }
  }
}
