import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { formatPrice } from '@core/string';
import { Nil } from '@model';
import { LocationEvseContract } from '@model/evd/locations';
import { ChannelValueComponent } from '@ui/channel-value';
import { IconComponent, IconName } from '@ui/icon';
import { LanguageService } from '@ui/language';
import { isNil } from 'lodash-es';

import { LocationEvseContractPricingMessages } from './location-evse-contract-pricing.types';

@Component({
  selector: 'app-location-evse-contract-pricing',
  standalone: true,
  imports: [CommonModule, ChannelValueComponent, IconComponent],
  templateUrl: './location-evse-contract-pricing.component.html',
  styleUrl: './location-evse-contract-pricing.component.scss',
})
export class LocationEvseContractPricingComponent {
  public constructor(private languageService: LanguageService) {}

  public contract = input.required<LocationEvseContract>();
  public messages = input.required<LocationEvseContractPricingMessages>();

  public batteryIcon: IconName = 'battery_full';
  public parkingIcon: IconName = 'local_parking';

  public fixedFee = computed(() => {
    return this.getPrice(this.contract().fixedFee);
  });

  public pricePerKwh = computed(() => {
    return this.getPrice(this.contract().pricePerKwh);
  });

  public pricePerMinute = computed(() => {
    return this.getPrice(this.contract().pricePerMinute);
  });

  public idleFee = computed(() => {
    return this.getIdleFee(this.contract(), this.messages());
  });

  private getPrice(price: number | Nil): string | Nil {
    if (isNil(price)) {
      return undefined;
    }

    return formatPrice(price, this.languageService.getLanguage());
  }

  private getIdleFee(
    contract: LocationEvseContract | Nil,
    messages: LocationEvseContractPricingMessages | Nil,
  ): string | Nil {
    const idleFee = contract?.idleFee;
    const idleFeeApplicableAfterMinutes =
      contract?.idleFeeApplicableAfterMinutes;

    if (
      isNil(contract) ||
      isNil(idleFee) ||
      isNil(messages) ||
      isNil(idleFeeApplicableAfterMinutes)
    ) {
      return undefined;
    }

    return messages.value.idleFee(
      contract?.currencyCode,
      `<strong>${formatPrice(
        idleFee,
        this.languageService.getLanguage(),
      )}</strong>`,
      `<strong>${idleFeeApplicableAfterMinutes}</strong>`,
    );
  }
}
