import { ElementRef } from '@angular/core';
import { isNotNil } from '@core/is-not-nil';
import { Nil } from '@model';
import { LatLng } from '@model/geography';
import * as d3 from 'd3';
import { debounce, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import Supercluster, { ClusterProperties } from 'supercluster';

import { Place } from '../address-picker';
import {
  Cluster,
  MapDataSource,
  MapDataSourceItem,
  MapDataSourceItemData,
  MapDataSourceItemStatus,
  Marker,
  SuperClusterFeature,
} from './map.types';
import { drawAddress, getAddressFromLatLng } from './utils/address.utils';
import { drawClusters, getClusters } from './utils/cluster.utils';
import { getBoundingBox, getPointFeatures } from './utils/map.utils';
import { drawMarkers, getMarkers } from './utils/marker.utils';
import { drawUserPosition } from './utils/user-position.utils';

export const CLUSTER_MIN_ZOOM = 8;
export const CLUSTER_MAX_ZOOM = 16;
export const CLUSTER_RADIUS = 128;

export class MapOverlay extends google.maps.OverlayView {
  public constructor(private minZoom: number | Nil) {
    super();
  }

  private clusterMinZoom = isNil(this.minZoom)
    ? CLUSTER_MIN_ZOOM
    : this.minZoom;

  private superCluster = new Supercluster<
    MapDataSourceItem<MapDataSourceItemData>,
    ClusterProperties
  >({
    radius: CLUSTER_RADIUS,
    minZoom: this.clusterMinZoom,
    maxZoom: CLUSTER_MAX_ZOOM,
  });
  private containerElement: Element | Nil;
  private svgElement: SVGSVGElement | Nil;
  private fragment: DocumentFragment = document.createDocumentFragment();
  private zooming = false;
  private place: Place | Nil;
  private editable = false;
  private showLabels = false;
  private debouncedLocationChange = debounce(this.onLocationChange, 500);
  private debouncedIdle = debounce(this.onIdle, 500);
  private userPosition: LatLng | Nil;
  private idled = false;

  public click$ = new Subject<any | Nil>();
  public placeChange$ = new Subject<Place | Nil>();

  public override onAdd(): void {
    this.containerElement = this.getPanes()?.overlayMouseTarget;

    if (isNotNil(this.containerElement)) {
      this.svgElement = d3.select(this.containerElement).append('svg').node();
    }

    this.getMap()?.addListener('zoom_changed', () => {
      this.onZoomChanged();
    });

    this.getMap()?.addListener('idle', () => {
      this.debouncedIdle();
    });
  }

  public setDataSource<Data extends MapDataSourceItemData>(
    dataSource: MapDataSource<Data> | Nil,
  ): void {
    this.superCluster.load(getPointFeatures(dataSource));
  }

  public setTooltipContainer(tooltipContainer: ElementRef): void {
    this.containerElement?.append(tooltipContainer.nativeElement);
  }

  public setPlace(place: Place | Nil): void {
    this.place = place;
  }

  public getPlace(): Place | Nil {
    return this.place;
  }

  public setUserPosition(latLng: LatLng | Nil): void {
    this.userPosition = latLng;
  }

  public setEditable(editable: boolean): void {
    this.editable = editable;
  }

  public setShowLabels(showLabels: boolean): void {
    this.showLabels = showLabels;
  }

  public isReady(): boolean {
    if (this.zooming || !this.svgElement || !this.containerElement) {
      return false;
    }

    return true;
  }

  public override draw(): void {
    if (this.zooming || !this.svgElement || !this.containerElement) {
      return;
    }

    const features = this.getFeatures();

    const markers: Marker[] = getMarkers(features, this.getProjection());
    const clusters: Cluster[] = getClusters(
      features,
      this.getProjection(),
      this.superCluster,
    );

    this.fragment.append(this.svgElement);

    drawClusters(this.svgElement, clusters, ({ clusterId, latLng }) => {
      const zoom = this.superCluster.getClusterExpansionZoom(clusterId);

      const map = this.getMap() as google.maps.Map;

      map.setZoom(zoom);
      map.setCenter(latLng);

      this.refresh();
    });

    drawMarkers(this.svgElement, markers, this.showLabels, (marker) => {
      return this.click$.next(marker.data);
    });

    drawAddress(
      this.svgElement,
      this.getAddressMarker(),
      this.getProjection(),
      this.editable,
      ({ latLng }) => {
        this.debouncedLocationChange(latLng);
      },
    );

    drawUserPosition(this.svgElement, this.getUserPositionMarker());

    this.containerElement.append(this.svgElement);
  }

  public refresh(): void {
    if (this.svgElement && this.containerElement) {
      this.svgElement.remove();
      this.svgElement = d3.select(this.containerElement).append('svg').node();

      this.draw();
      this.draw();
    }
  }

  private onLocationChange(latLng: LatLng): void {
    getAddressFromLatLng(latLng, (results) => {
      if (this.place) {
        if (isNotNil(results) && results.length > 0) {
          this.place = { ...this.place, ...results[0] };
        }
        if (this.place.geometry) {
          this.place.geometry.location = latLng;
          this.placeChange$.next(this.place);
        }
      }
    });
  }

  private getFeatures(): SuperClusterFeature[] {
    if (isNil(this.getMap())) {
      return [];
    }

    const zoom = this.getMap()?.getZoom();
    const map = this.getMap() as google.maps.Map;
    const bbox = getBoundingBox(map.getBounds());

    if (isNil(zoom)) {
      return [];
    }

    return bbox && zoom >= this.clusterMinZoom
      ? this.superCluster.getClusters(bbox, zoom)
      : [];
  }

  private getAddressMarker(): Marker<any> | Nil {
    if (isNil(this.place) || isNil(this.place.geometry)) {
      return undefined;
    }

    const point = this.getProjection().fromLatLngToDivPixel(
      this.place.geometry.location ?? null,
    );

    const latLng = this.place.geometry.location;

    if (isNil(point) || isNil(latLng)) {
      return undefined;
    }

    return {
      id: 'address',
      latLng,
      count: 0,
      statuses: {
        [MapDataSourceItemStatus.Charging]: 0,
        [MapDataSourceItemStatus.Error]: 0,
        [MapDataSourceItemStatus.Offline]: 0,
        [MapDataSourceItemStatus.Online]: 0,
        [MapDataSourceItemStatus.Warning]: 0,
      },
      point,
      data: this.place,
    };
  }

  public getUserPositionMarker(): Nil | Marker<any> {
    if (isNil(this.userPosition)) {
      return undefined;
    }

    const point = this.getProjection().fromLatLngToDivPixel(
      this.userPosition || null,
    );

    if (isNil(point)) {
      return undefined;
    }

    return {
      id: 'user-position',
      latLng: this.userPosition,
      count: 0,
      statuses: {
        [MapDataSourceItemStatus.Charging]: 0,
        [MapDataSourceItemStatus.Error]: 0,
        [MapDataSourceItemStatus.Offline]: 0,
        [MapDataSourceItemStatus.Online]: 0,
        [MapDataSourceItemStatus.Warning]: 0,
      },
      point,
      data: {},
    };
  }

  private onZoomChanged(): void {
    this.zooming = true;
    this.containerElement?.setAttribute('style', 'opacity: 0');
    this.click$.next(undefined);
  }

  private onIdle(): void {
    if (this.zooming) {
      this.zooming = false;
      setTimeout(
        () => {
          this.draw();
          this.containerElement?.setAttribute(
            'style',
            'opacity: 1; transition: opacity 1s;',
          );
        },
        this.idled ? 0 : 500,
      );
    }
    this.draw();
    this.idled = true;
  }
}
