import { Nil, WithId, WithName } from '@model';
import { Bounds, LatLng, Point } from '@model/geography';
import { WithCoordinates } from '@model/with-coordinates';
import { has } from 'lodash-es';
import { ClusterProperties, PointFeature } from 'supercluster';

export interface MapMessages {
  search?: string;
}

export type MapDataSourceItemData = WithId & WithCoordinates & WithName;

export interface MapDataSource<Data extends MapDataSourceItemData> {
  items: MapDataSourceItem<Data>[];
}

export enum MapDataSourceItemStatus {
  Online = 'online',
  Offline = 'offline',
  Charging = 'charging',
  Error = 'error',
  Warning = 'warning',
}

export enum AutoFitBounds {
  Never,
  Always,
  Once,
}

export interface MapDataSourceItem<Data extends MapDataSourceItemData>
  extends WithId {
  latLng: LatLng;
  label?: string;
  statuses: Record<MapDataSourceItemStatus, number>;
  data: Data;
}

export interface Marker<Data extends MapDataSourceItemData = any>
  extends MapDataSourceItem<Data> {
  point: Point;
  count: number;
  label?: string;
}

export interface Cluster {
  latLng: LatLng;
  point: Point;
  count: number;
  clusterId: number;
  statuses: Record<string, number>;
}

export interface PieChartPart extends WithId {
  point: Point;
  status: string;
  startAngle: number;
  endAngle: number;
}

export interface BoundsChangeEvent {
  bounds: Bounds | Nil;
  userPosition: LatLng | Nil;
}

export type SuperClusterFeature =
  | PointFeature<MapDataSourceItem<MapDataSourceItemData>>
  | PointFeature<ClusterProperties>;

export function isCluster(
  feature: SuperClusterFeature,
): feature is PointFeature<ClusterProperties> {
  return has(feature, ['properties', 'cluster_id']);
}

export function isMarker(
  feature: SuperClusterFeature,
): feature is PointFeature<MapDataSourceItem<MapDataSourceItemData>> {
  return !isCluster(feature);
}
