import { Point } from '@model/geography';
import * as d3 from 'd3';
import { sum } from 'lodash-es';

import { Marker, SuperClusterFeature, isMarker } from '../map.types';
import {
  BaseElement,
  EnterElement,
  SvgElement,
  enterCircle,
  enterCounter,
  enterLabel,
  enterMarkerPieChart,
  enterPath,
} from '../utils/d3-utils';

export const MARKER_PATH =
  'M5 0C2.23929 0 0 2.1945 0 4.9C0 8.575 5 14 5 14C5 14 10 8.575 10 4.9C10 2.1945 7.76071 0 5 0Z';

export function getMarkers(
  features: SuperClusterFeature[],
  projection: google.maps.MapCanvasProjection,
): Marker[] {
  return features.filter(isMarker).map(({ properties }) => {
    return {
      ...properties,
      point:
        projection.fromLatLngToDivPixel(properties.latLng) ?? new Point(0, 0),
      count: sum(Object.values(properties?.statuses || {})),
      statuses: properties.statuses,
    };
  });
}

export function drawMarkers(
  svgElement: Element,
  markers: Marker[],
  showLabels: boolean,
  callback: (marker: Marker) => void,
): void {
  d3.select(svgElement)
    .selectAll<d3.BaseType, Marker>('g.marker')
    .data(markers, (datum) => {
      return datum ? `${datum.id}` : '';
    })
    .join(enterFunction(showLabels), updateFunction, (exit) => {
      return exit.remove();
    })
    .on('click', (_cluster, datum) => {
      return callback(datum);
    });
}

function enterFunction(
  showLabels: boolean,
): (selection: EnterElement<Marker>) => SvgElement<Marker> {
  return (selection: EnterElement<Marker>) => {
    const group = selection.append('g').attr('class', 'marker');

    enterPath<Marker>(
      MARKER_PATH,
      'pin',
    )(group).attr('transform', 'translate(17 28)');

    if (showLabels) {
      enterLabel<Marker>('label-back')(group);
      enterLabel<Marker>('label-front')(group);
    }

    const pieGroup = group.append('g').attr('transform', 'translate(22 22)');

    enterCircle<Marker>('outer', 15)(pieGroup);
    enterCircle<Marker>('inner', 11)(pieGroup);
    enterCounter<Marker>()(pieGroup);
    enterMarkerPieChart('pie', 11, 15)(pieGroup);

    return group;
  };
}

function updateFunction(selection: BaseElement<Marker>): BaseElement<Marker> {
  return selection.attr('transform', ({ point }) => {
    return `translate(${point.x - 28} ${point.y - 40})`;
  });
}
