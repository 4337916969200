import { Nil } from '@model';
import { BoundingBox, Bounds } from '@model/geography';
import { isNil } from 'lodash-es';
import { PointFeature } from 'supercluster';

import {
  MapDataSource,
  MapDataSourceItem,
  MapDataSourceItemData,
} from '../map.types';

export function getBoundingBox(bounds: Bounds | Nil): BoundingBox | Nil {
  if (isNil(bounds)) {
    return undefined;
  }

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  return [sw.lng(), sw.lat(), ne.lng(), ne.lat()];
}

export function getPointFeatures(
  dataSource: MapDataSource<MapDataSourceItemData> | Nil,
): PointFeature<MapDataSourceItem<MapDataSourceItemData>>[] {
  const items = dataSource ? dataSource.items : [];
  return items.map((item) => {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [item.latLng.lng(), item.latLng.lat()],
      },
      properties: item,
    };
  });
}
