<etn-list-item [action]="backAction" [chevronIcon]="undefined">
  <div etn-title>{{ location().name }}</div>
  <div etn-subtitle>{{ location().formattedAddress }}</div>
  <div etn-right-content>
    <etn-fab-icon
      [name]="locationIcon"
      [size]="locationIconSize"
      (click)="onDriveToLocation(location())"
    ></etn-fab-icon>
  </div>
</etn-list-item>
<div class="evses">
  @if (loading()) {
    <etn-progress-spinner></etn-progress-spinner>
  } @else {
    @for (evse of evses(); track evse.id) {
      <app-location-evse-container
        [evse]="evse"
        [messages]="locationEvseMessages"
        (click)="evseView.emit(evse)"
      ></app-location-evse-container>
    }
  }
</div>
