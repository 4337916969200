import { IdJsonSchema } from '@api';
import { LinksJson } from '@api/hateos';
import { environment } from '@env/evd/environment';
import { ConnectorType, EvseStatus } from '@model';
import { Location } from '@model/evd/locations';
import { z } from 'zod';

import { LocationEvseJsonSchema } from './location-evse.io';

export const LocationJsonSchema = z.object({
  locationId: IdJsonSchema,
  name: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  evses: z.array(
    LocationEvseJsonSchema.pick({
      chargerId: true,
      evseId: true,
    }).extend({
      evseStatus: z.nativeEnum(EvseStatus),
    }),
  ),
  formattedAddress: z.string(),
  maxKw: z.number(),
  minKw: z.number(),
  connectorTypes: z.record(z.nativeEnum(ConnectorType), z.number()),
  _links: LinksJson(environment.urls.api).optional(),
});

export type LocationJson = z.infer<typeof LocationJsonSchema>;

export function serializeLocation(location: Location): LocationJson {
  return {
    ...location,
    locationId: location.id,
    latitude: location.coordinates?.latitude ?? 0,
    longitude: location.coordinates?.longitude ?? 0,
    evses: location.evses.map((evse) => {
      return {
        chargerId: evse.id,
        evseId: evse.evseId,
        evseStatus: evse.status,
      };
    }),
  };
}

export function deserializeLocation(location: LocationJson): Location {
  return {
    ...location,
    id: location.locationId,
    coordinates: {
      latitude: location.latitude,
      longitude: location.longitude,
    },
    evses: location.evses.map((evse) => {
      return {
        id: evse.chargerId,
        evseId: evse.evseId,
        status: evse.evseStatus,
      };
    }),
  };
}
