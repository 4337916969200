import { Nil } from '@model';
import { LatLng } from '@model/geography';

export const DEFAULT_LAT_LNG = new LatLng(46.54152, 6.627972);

export function getCurrentUserLatLng(
  callback: (latLng: LatLng | Nil) => void,
): void {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      const latLng: LatLng = new LatLng(
        position.coords.latitude,
        position.coords.longitude,
      );
      callback(latLng);
    },
    () => {
      callback(undefined);
    },
    {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: Infinity,
    },
  );
}

export function watchUserLatLng(
  callback: (latLng: LatLng | Nil) => void,
): void {
  navigator.geolocation.watchPosition(
    (position) => {
      const latLng: LatLng = new LatLng(
        position.coords.latitude,
        position.coords.longitude,
      );
      callback(latLng);
    },
    () => {
      callback(undefined);
    },
    {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: Infinity,
    },
  );
}

export function calculateDistanceInMeters(from: LatLng, to: LatLng): number {
  return google.maps.geometry.spherical.computeDistanceBetween(from, to);
}
