import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Link, LinkMethod } from '@model';
import {
  ListLocationEvse,
  LocationEvse,
  LocationEvseContract,
  LocationEvseContractTransactionStartStatus,
  getTransactionStartLink,
} from '@model/evd/locations';
import { isNil } from 'lodash-es';
import { Observable, catchError, map, of } from 'rxjs';
import { z } from 'zod';

import { AbstractEvdApiService } from '../evd-api.service';
import {
  ListLocationEvseJson,
  ListLocationEvseJsonSchema,
  LocationEvseJson,
  LocationEvseJsonSchema,
  deserializeListLocationEvse,
  deserializeLocationEvse,
  serializeLocationEvse,
} from './io/location-evse.io';

@Injectable({
  providedIn: 'root',
})
export class LocationEvsesApiService extends AbstractEvdApiService<
  LocationEvse,
  LocationEvseJson,
  ListLocationEvse,
  ListLocationEvseJson
> {
  public constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  protected serialize(location: LocationEvse): LocationEvseJson {
    return serializeLocationEvse(location);
  }

  protected deserialize(location: LocationEvseJson): LocationEvse {
    return deserializeLocationEvse(location);
  }

  protected getLinksLink(): Link {
    throw new Error('Not available');
  }

  protected getJsonSchema(): z.Schema<LocationEvseJson> {
    return LocationEvseJsonSchema;
  }

  protected getListJsonSchema(): z.Schema<ListLocationEvseJson> {
    return ListLocationEvseJsonSchema;
  }

  protected listDeserialize(item: ListLocationEvseJson): ListLocationEvse {
    return deserializeListLocationEvse(item);
  }

  public startTransaction(
    evse: LocationEvse,
    contract: LocationEvseContract,
  ): Observable<LocationEvseContractTransactionStartStatus | Error> {
    const link = getTransactionStartLink(contract);
    if (isNil(link)) {
      return of(new Error());
    }
    this.checkLinkMethod(link, LinkMethod.POST);
    return this.httpClient
      .post<{ status: LocationEvseContractTransactionStartStatus }>(link.href, {
        chargerId: evse.chargerId.toString(),
        evseId: evse.evseId.toString(),
        driverContractId: contract.id.toString(),
      })
      .pipe(
        map((response) => {
          return response.status;
        }),
        catchError(() => {
          return of(new Error());
        }),
      );
  }
}
