import { Nil } from '@model';
import { LatLng, Point } from '@model/geography';
import * as d3 from 'd3';

import { Marker } from '../map.types';
import {
  BaseElement,
  EnterElement,
  SvgElement,
  enterCircle,
  enterPath,
} from './d3-utils';
import { MARKER_PATH } from './marker.utils';

export function getAddressFromLatLng(
  latLng: LatLng,
  callback: (results: google.maps.GeocoderResult[] | Nil) => void,
): void {
  const geocoder = new google.maps.Geocoder();
  geocoder.geocode({ location: latLng }, (results) => {
    return callback(results);
  });
}

export function drawAddress(
  svgElement: Element,
  address: Marker | Nil,
  projection: google.maps.MapCanvasProjection,
  editable: boolean,
  callback: (marker: Marker) => void,
): void {
  d3.select(svgElement)
    .selectAll<d3.BaseType, Marker>('g.address')
    .data(address ? [address] : [], (datum) => {
      return datum ? `${datum.id}` : '';
    })
    .join(
      (element) => {
        return enterFunction(element, projection, editable, callback);
      },
      updateFunction,
      (exit) => {
        return exit.remove();
      },
    );
}

function enterFunction(
  selection: EnterElement<Marker>,
  projection: google.maps.MapCanvasProjection,
  editable: boolean,
  callback: (marker: Marker) => void,
): SvgElement<Marker> {
  const group = selection.append('g').attr('class', 'address');

  if (editable) {
    // NOTE: all the "any" are due to the fact d3 typings are not up to date
    // TODO: fix this when typings will be fixed/updated
    const drag = d3.drag<any, Marker>().on('drag', (event: any, datum: any) => {
      datum.point = new Point(event.x, event.y);
      datum.latLng = projection.fromDivPixelToLatLng(datum.point);
      updateFunction(group as any);
      callback(datum);
    });
    group.call(drag);
  }

  enterPath<Marker>(
    MARKER_PATH,
    'pin',
  )(group).attr('transform', 'translate(17 27)');
  enterCircle<Marker>('outer', 15)(group).attr('transform', 'translate(22 22)');
  enterCircle<Marker>('inner', 11)(group).attr('transform', 'translate(22 22)');

  return group;
}

function updateFunction(selection: BaseElement<Marker>): BaseElement<Marker> {
  return selection.attr('transform', ({ point }) => {
    return `translate(${point.x - 28} ${point.y - 40})`;
  });
}
