import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { isNotNil } from '@core/is-not-nil';
import { MESSAGES } from '@i18n/evd';
import { CheckboxComponent } from '@ui/checkbox';
import {
  CheckboxGroupComponent,
  CheckboxGroupDisplay,
} from '@ui/checkbox-group';
import { AbstractDialogComponent, DialogConfig } from '@ui/dialog';
import { LanguageService } from '@ui/language';
import { ListItemSeparatorComponent } from '@ui/list-item';
import { SelectableItem } from '@ui/selectable-item';

import { DashboardPageFiltersDialogData } from './dashboard-page-filters-dialog.types';

@Component({
  selector: 'app-dashboard-page-filters-dialog',
  standalone: true,
  imports: [
    CommonModule,
    CheckboxGroupComponent,
    CheckboxComponent,
    ListItemSeparatorComponent,
  ],
  templateUrl: './dashboard-page-filters-dialog.component.html',
  styleUrl: './dashboard-page-filters-dialog.component.scss',
})
export class DashboardPageFiltersDialogComponent extends AbstractDialogComponent<DashboardPageFiltersDialogData> {
  public constructor(
    @Inject(DIALOG_DATA)
    private dialogConfig: DialogConfig<DashboardPageFiltersDialogData>,
    private languageService: LanguageService,
  ) {
    super();
    this.result = this.dialogConfig.data;
  }

  public values: SelectableItem[] = (
    this.dialogConfig.data?.contracts ?? []
  ).map((contract) => {
    return {
      id: contract.id,
      name: this.languageService.getValue(contract.name),
    };
  });

  public value = this.dialogConfig.data?.selectedContractIds ?? [];

  public display = CheckboxGroupDisplay.Vertical;

  public messages = MESSAGES.dashboardPage;

  public allSelected = this.values.length === this.value.length;
  public someSelected = this.value.length > 0 && !this.allSelected;

  public onValueChange(value: string[]) {
    if (isNotNil(this.result)) {
      this.result.selectedContractIds = value;
      this.allSelected = this.values.length === value.length;
      this.someSelected = value.length > 0 && !this.allSelected;
    }
  }

  public onSelectAllClick(): void {
    if (isNotNil(this.result)) {
      if (this.allSelected) {
        this.value = [];
      } else {
        this.value = this.values.map((item) => {
          return item.id.toString();
        });
      }

      this.result.selectedContractIds = this.value;
      this.someSelected =
        this.result.selectedContractIds.length > 0 && !this.allSelected;
    }
  }
}
