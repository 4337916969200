@if (evse(); as evse) {
  <etn-list-item [action]="backAction" [chevronIcon]="undefined">
    <div etn-title>{{ name() }}</div>
  </etn-list-item>

  <app-location-evse-connectors-container
    [evse]="evse"
    [messages]="evseConnectorsMessages"
  ></app-location-evse-connectors-container>

  <div appPrimarySubtitle2>{{ availableContracts() }}</div>
  <etn-list-item-separator></etn-list-item-separator>

  <div class="contracts">
    @for (contract of evse.contracts; track contract.id) {
      <app-location-evse-contract
        [contract]="contract"
        [messages]="evseContractMessages"
        [selected]="contract.id === selectedContract()?.id"
        (click)="onContractClick(contract)"
      ></app-location-evse-contract>
    }

    <etn-action [action]="startChargingAction()"></etn-action>
  </div>
} @else {
  <etn-progress-spinner></etn-progress-spinner>
}
