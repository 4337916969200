<div class="icons">
  @for (name of icons(); track $index) {
    <etn-fab-icon [name]="name" [color]="color" [size]="size"></etn-fab-icon>
  }
</div>
<div class="labels-and-status">
  <div class="labels">{{ labels() }}</div>
  <div class="status">
    <etn-icon
      [name]="statusIconName()"
      [color]="statusIconColor()"
      [size]="statusIconSize"
    ></etn-icon>
    <span
      appCaption
      [ngStyle]="{
        color: statusColor(),
      }"
      >{{ evse().status }}</span
    >
  </div>
</div>
