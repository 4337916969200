import { CommonModule } from '@angular/common';
import {
  Component,
  DestroyRef,
  OnInit,
  Signal,
  computed,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { filterNil } from '@core/rxjs/filter-nil';
import { MESSAGES, getPlural } from '@i18n/evd';
import { Nil } from '@model';
import {
  LocationEvseContract,
  getLocationEvseDisplayName,
  getLocationEvseEnabledContracts,
  hasTransactionStartLink,
} from '@model/evd/locations';
import { Action, ActionAlign, ActionColor, ActionComponent } from '@ui/action';
import { IconSize } from '@ui/icon';
import { ListItemComponent, ListItemSeparatorComponent } from '@ui/list-item';
import { ProgressSpinnerComponent } from '@ui/progress-spinner';
import { first, isNil } from 'lodash-es';

import { LocationEvseConnectorsMessages } from '../../../../locations/components/location-evse-connectors';
import {
  LocationEvseContractComponent,
  LocationEvseContractMessages,
} from '../../../../locations/components/location-evse-contract';
import { LocationEvseConnectorsContainerComponent } from '../../../../locations/containers/location-evse-connectors-container';
import { DashboardPageService } from '../dashboard-page.service';

@Component({
  selector: 'app-dashboard-page-location-evse-panel',
  standalone: true,
  imports: [
    ActionComponent,
    CommonModule,
    ListItemComponent,
    ListItemSeparatorComponent,
    LocationEvseConnectorsContainerComponent,
    LocationEvseContractComponent,
    ProgressSpinnerComponent,
  ],
  templateUrl: './dashboard-page-location-evse-panel.component.html',
  styleUrl: './dashboard-page-location-evse-panel.component.scss',
})
export class DashboardPageLocationEvsePanelComponent implements OnInit {
  public constructor(
    private service: DashboardPageService,
    private destroyRef: DestroyRef,
  ) {}

  public back = output<void>();

  public evse = toSignal(this.service.evse$);

  public name: Signal<string> = computed(() => {
    const evse = this.evse();
    if (isNil(evse)) {
      return '';
    }
    return getLocationEvseDisplayName(evse);
  });

  public backAction: Action = {
    id: 'back',
    icon: 'arrow_back',
    iconSize: IconSize.Default,
    color: ActionColor.LightTransparent,
    callback: () => {
      this.back.emit();
    },
  };

  public selectedContract = signal<LocationEvseContract | Nil>(undefined);

  public startChargingAction: Signal<Action> = computed(() => {
    return {
      id: 'start-charging',
      name: MESSAGES.dashboardPage.button.startCharging,
      color: ActionColor.Primary,
      callback: () => {
        this.service.startCharging(this.evse(), this.selectedContract());
      },
      raised: true,
      fullWidth: true,
      icon: 'bolt',
      height: 48,
      align: ActionAlign.Center,
      disabled: isNil(this.selectedContract()),
    };
  });

  public evseConnectorsMessages: LocationEvseConnectorsMessages = {
    unit: MESSAGES.general.unit,
  };

  public evseContractMessages: LocationEvseContractMessages = {
    ...MESSAGES.dashboardPage,
    unit: {
      perKWh: MESSAGES.general.unit.perKWh,
      perMinute: MESSAGES.general.unit.perMinute,
    },
  };

  public availableContracts: Signal<string> = computed(() => {
    const evse = this.evse();
    if (isNil(evse)) {
      return '';
    }
    return getPlural(
      MESSAGES.dashboardPage.title.availableContracts,
      evse.contracts.length,
    );
  });

  public ngOnInit(): void {
    this.service.evse$
      .pipe(filterNil(), takeUntilDestroyed(this.destroyRef))
      .subscribe((evse) => {
        this.selectedContract.set(first(getLocationEvseEnabledContracts(evse)));
      });
  }

  public onContractClick(contract: LocationEvseContract): void {
    if (hasTransactionStartLink(contract)) {
      this.selectedContract.set(contract);
    }
  }
}
