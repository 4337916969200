<ng-container *ngLet="userPosition$ | async as userPosition">
  <etn-map
    #map
    [autoFitBounds]="autoFitBounds"
    [center]="center$ | async"
    [dataSource]="dataSource?.mapDataSource$ | async"
    [messages]="messages"
    [search]="search"
    [userPosition]="userPosition"
    [zoom]="zoom"
    [minZoom]="minZoom"
    [editable]="editable"
    (boundsChange)="onBoundsChanged($event)"
    (itemSelect)="itemSelect.emit($event)"
    (ready)="onReady($event)"
    (valueChange)="onValueChange($event)"
  ></etn-map>
  @if ((dataSource?.ready$ | async) === false) {
    <etn-progress-spinner></etn-progress-spinner>
  }

  @for (action of actions; track action.id) {
    <etn-action #action [action]="action"></etn-action>
  }

  @if (enableUserPosition) {
    <etn-user-position-action
      #action
      (actionClick)="onUserPositionClick()"
    ></etn-user-position-action>
  }
</ng-container>
