import { IdJsonSchema } from '@api';
import { LinksJson } from '@api/hateos';
import { environment } from '@env/evd/environment';
import { ConnectorType, EvseStatus } from '@model';
import { ListLocationEvse, LocationEvse } from '@model/evd/locations';
import { z } from 'zod';

import {
  LocationEvseContractJsonSchema,
  deserializeLocationEvseContract,
  serializeLocationEvseContract,
} from './location-evse-contract.io';

export const LocationEvseJsonSchema = z.object({
  chargerId: IdJsonSchema,
  chargerName: z.string().optional(),
  chargerSerialNumber: z.string().optional(),
  connectorTypes: z.array(z.nativeEnum(ConnectorType)),
  evseId: IdJsonSchema,
  maxKw: z.number().optional(),
  status: z.nativeEnum(EvseStatus),
  contracts: z.array(LocationEvseContractJsonSchema),
  _links: LinksJson(environment.urls.api).optional(),
});

export type LocationEvseJson = z.infer<typeof LocationEvseJsonSchema>;

export const ListLocationEvseJsonSchema = z.object({
  chargerId: IdJsonSchema,
  chargerName: z.string().optional(),
  chargerSerialNumber: z.string().optional(),
  connectorTypes: z.array(z.nativeEnum(ConnectorType)),
  evseId: IdJsonSchema,
  fixedFee: z.number().optional(),
  hasMultiplePricings: z.boolean().optional(),
  idleFee: z.number().optional(),
  idleFeeApplicableAfterMinutes: z.number().optional(),
  maxKw: z.number().optional(),
  pricePerKwh: z.number().optional(),
  pricePerMinute: z.number().optional(),
  status: z.nativeEnum(EvseStatus),
  currencyCode: z.string(),
  _links: LinksJson(environment.urls.api).optional(),
});

export type ListLocationEvseJson = z.infer<typeof ListLocationEvseJsonSchema>;

export function serializeLocationEvse(evse: LocationEvse): LocationEvseJson {
  return {
    ...evse,
    contracts: evse.contracts.map(serializeLocationEvseContract),
  };
}

export function deserializeLocationEvse(evse: LocationEvseJson): LocationEvse {
  return {
    id: `${evse.chargerId}-${evse.evseId}`,
    ...evse,
    contracts: evse.contracts.map(deserializeLocationEvseContract),
  };
}

export function deserializeListLocationEvse(
  evse: ListLocationEvseJson,
): ListLocationEvse {
  return {
    ...evse,
    id: `${evse.chargerId}-${evse.evseId}`,
  };
}
