@if (locations(); as locations) {
  @if (locations.length > 0) {
    <etn-map-container
      [dataSource]="locationsDataSource()"
      [autoFitBounds]="autoFitBounds"
      [enableUserPosition]="true"
      [zoom]="18"
      [minZoom]="1"
      [search]="true"
      [messages]="mapMessages"
      [editable]="false"
      [actions]="actions()"
      (itemSelect)="onItemSelect($event)"
    ></etn-map-container>

    @if (location(); as location) {
      @if (evse(); as evse) {
        <app-dashboard-page-location-evse-panel
          (back)="onEvseBack()"
        ></app-dashboard-page-location-evse-panel>
      } @else {
        <app-dashboard-page-location-panel
          [location]="location"
          (back)="onLocationBack()"
          (evseView)="onEvseView($event)"
        ></app-dashboard-page-location-panel>
      }
    } @else {
      <app-dashboard-page-locations-panel
        [locations]="locations"
        [userLatLng]="userLatLng"
        (locationView)="onLocationView($event)"
        (locationSwipe)="onLocationSwipe($event)"
      ></app-dashboard-page-locations-panel>
    }
  } @else {
    <etn-empty-state
      [title]="messages.emptyState.title"
      [description]="messages.emptyState.description"
      [icon]="emptyStateIcon"
    ></etn-empty-state>
  }
}
