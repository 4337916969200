import { Id } from '@model';
import { Point } from '@model/geography';
import * as d3 from 'd3';
import { sum } from 'lodash-es';

import {
  Cluster,
  MapDataSourceItemStatus,
  Marker,
  PieChartPart,
} from '../map.types';

const TAU = 2 * Math.PI;

export type SvgElement<Datum> = d3.Selection<
  SVGGElement,
  Datum,
  Element,
  unknown
>;
export type D3EnterElement<Datum> = d3.Selection<
  d3.EnterElement,
  Datum,
  Element,
  unknown
>;
export type EnterElement<Datum> = D3EnterElement<Datum> | SvgElement<Datum>;
export type CircleElement<Datum> = d3.Selection<
  SVGCircleElement,
  Datum,
  Element,
  unknown
>;
export type TextElement<Datum> = d3.Selection<
  SVGTextElement,
  Datum,
  Element,
  unknown
>;
export type PathElement<Datum> = d3.Selection<
  SVGPathElement,
  Datum,
  Element,
  unknown
>;
export type BaseElement<Datum> = d3.Selection<
  d3.BaseType,
  Datum,
  Element,
  unknown
>;

export function enterCircle<Datum>(
  cssClass = '',
  radius = 20,
): (selection: EnterElement<Datum>) => CircleElement<Datum> {
  return (selection: EnterElement<Datum>) => {
    return selection.append('circle').attr('class', cssClass).attr('r', radius);
  };
}

export function enterCounter<Datum extends { count: number }>(
  cssClass = 'text',
): (selection: EnterElement<Datum>) => TextElement<Datum> {
  return (selection: EnterElement<Datum>) => {
    return selection
      .append('text')
      .attr('class', cssClass)
      .attr('y', '4')
      .text(({ count }) => {
        return count > 0 ? count : '';
      })
      .style('text-anchor', 'middle');
  };
}

export function enterLabel<Datum extends { label?: string }>(
  cssClass = 'text',
): (selection: EnterElement<Datum>) => TextElement<Datum> {
  return (selection: EnterElement<Datum>) => {
    return selection
      .append('text')
      .attr('class', cssClass)
      .attr('y', '-8')
      .attr('x', 14)
      .text(({ label }) => {
        return label ?? '';
      })
      .style('text-anchor', 'middle');
  };
}

export function enterPath<Datum>(
  path: string,
  cssClass = '',
): (selection: EnterElement<Datum>) => PathElement<Datum> {
  return (selection: EnterElement<Datum>) => {
    return selection.append('path').attr('class', cssClass).attr('d', path);
  };
}

export function enterPiePart(
  cssClass = '',
  innerRadius = 24,
  outerRadius = 28,
): (selection: EnterElement<PieChartPart>) => PathElement<PieChartPart> {
  return (selection: EnterElement<PieChartPart>) => {
    return selection
      .append('path')
      .attr('class', ({ status }) => {
        return `${status} ${cssClass}`;
      })
      .attr('d', ({ startAngle, endAngle }) => {
        return (
          d3.arc()({ innerRadius, outerRadius, startAngle, endAngle }) ?? ''
        );
      });
  };
}

export function enterMarkerPieChart(
  cssClass = 'pie',
  innerRadius = 14,
  outerRadius = 20,
): (
  selection: d3.Selection<SVGGElement, Marker, Element, unknown>,
) => d3.Selection<Element, Marker, Element, unknown> {
  return (selection: d3.Selection<SVGGElement, Marker, Element, unknown>) => {
    return selection.append((marker) => {
      const pieGroup: Element = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'g',
      );
      const parts = getPieParts(marker.id, marker.point, marker.statuses);

      d3.select(pieGroup)
        .selectAll<d3.BaseType, PieChartPart>('path')
        .data(parts)
        .join(enterPiePart(cssClass, innerRadius, outerRadius));

      return pieGroup;
    });
  };
}

export function enterClusterPieChart(
  cssClass = 'pie',
  innerRadius = 20,
  outerRadius = 34,
): (
  selection: d3.Selection<SVGGElement, Cluster, Element, unknown>,
) => d3.Selection<Element, Cluster, Element, unknown> {
  return (selection: d3.Selection<SVGGElement, Cluster, Element, unknown>) => {
    return selection.append((cluster) => {
      const pieGroup: Element = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'g',
      );
      const parts = getPieParts(
        cluster.clusterId,
        cluster.point,
        cluster.statuses,
      );
      d3.select(pieGroup)
        .selectAll<d3.BaseType, PieChartPart>('path')
        .data(parts)
        .join(enterPiePart(cssClass, innerRadius, outerRadius));
      return pieGroup;
    });
  };
}

export function getPieParts(
  id: Id,
  point: Point,
  statuses: Record<MapDataSourceItemStatus, number>,
): PieChartPart[] {
  const keys = Object.keys(statuses) as MapDataSourceItemStatus[];
  const total = sum(
    Object.values(statuses).map((values) => {
      return values;
    }),
  );

  let startAngle = 0;

  return keys
    .filter((status) => {
      // we ignore status with value equal to zero
      return statuses[status] > 0;
    })
    .map((status) => {
      const angle = (statuses[status] / (total / 100) / 100) * TAU;
      const endAngle = startAngle + angle;
      const part: PieChartPart = {
        id: `${id}-${status}`,
        point,
        status,
        startAngle,
        endAngle,
      };
      startAngle = endAngle;
      return part;
    });
}
