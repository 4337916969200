import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  computed,
  input,
} from '@angular/core';
import {
  CONNECTOR_TYPE_ICON,
  CONNECTOR_TYPE_TRANSLATIONS,
} from '@core/evd/utils';
import {
  EVSE_STATUS_COLORS,
  EVSE_STATUS_ICONS,
  EVSE_STATUS_ICON_COLORS,
} from '@core/evd/utils/evse-status.utils';
import { formatPower } from '@core/string';
import { Nil } from '@model';
import { ListLocationEvse, LocationEvse } from '@model/evd/locations';
import { FabIconColor, FabIconComponent } from '@ui/fab-icon';
import { IconComponent, IconName, IconSize } from '@ui/icon';
import { LanguageService } from '@ui/language';
import { isNil } from 'lodash-es';

import { LocationEvseConnectorsMessages } from './location-evse-connectors.types';

@Component({
  selector: 'app-location-evse-connectors',
  standalone: true,
  imports: [CommonModule, FabIconComponent, IconComponent],
  templateUrl: './location-evse-connectors.component.html',
  styleUrl: './location-evse-connectors.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationEvseConnectorsComponent {
  public constructor(private languageService: LanguageService) {}

  public evse = input.required<LocationEvse | ListLocationEvse>();
  public messages = input.required<LocationEvseConnectorsMessages>();

  public icons: Signal<IconName[]> = computed(() => {
    return (this.evse().connectorTypes ?? []).map((connector) => {
      return CONNECTOR_TYPE_ICON[connector];
    });
  });

  public labels: Signal<string> = computed(() => {
    const connectors = (this.evse().connectorTypes ?? [])
      .map((connector) => {
        return CONNECTOR_TYPE_TRANSLATIONS[connector];
      })
      .join(' / ');

    const maxKw = this.maxKw();

    if (isNil(maxKw)) {
      return connectors;
    }

    return `${connectors} - ${maxKw} ${this.messages().unit.kW}`;
  });

  public name: Signal<string> = computed(() => {
    return this.evse().evseId.toString();
  });

  public maxKw: Signal<string | Nil> = computed(() => {
    const maxKw = this.evse().maxKw;
    if (isNil(maxKw)) {
      return undefined;
    }
    return formatPower(maxKw, this.languageService.getLanguage());
  });

  public statusIconName = computed(() => {
    return EVSE_STATUS_ICONS[this.evse().status];
  });

  public statusIconColor = computed(() => {
    return EVSE_STATUS_ICON_COLORS[this.evse().status];
  });

  public statusColor = computed(() => {
    return EVSE_STATUS_COLORS[this.evse().status][500];
  });

  public statusIconSize: IconSize = IconSize.Msmall;

  public size: IconSize = IconSize.Msmall;
  public color: FabIconColor = FabIconColor.Grey;
}
