import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MESSAGES } from '@i18n/evd';
import { ListLocationEvse, Location } from '@model/evd/locations';
import { Action, ActionColor } from '@ui/action';
import { FabIconComponent } from '@ui/fab-icon';
import { IconName, IconSize } from '@ui/icon';
import { ListItemComponent } from '@ui/list-item';
import { ProgressSpinnerComponent } from '@ui/progress-spinner';

import { LocationEvseMessages } from '../../../../locations/components/location-evse';
import { LocationEvseContainerComponent } from '../../../../locations/containers/location-evse-container';
import { DashboardPageService } from '../dashboard-page.service';

@Component({
  selector: 'app-dashboard-page-location-panel',
  standalone: true,
  imports: [
    CommonModule,
    FabIconComponent,
    ListItemComponent,
    LocationEvseContainerComponent,
    ProgressSpinnerComponent,
  ],
  templateUrl: './dashboard-page-location-panel.component.html',
  styleUrl: './dashboard-page-location-panel.component.scss',
})
export class DashboardPageLocationPanelComponent {
  public constructor(private service: DashboardPageService) {}

  public location = input.required<Location>();

  public back = output<void>();
  public evseView = output<ListLocationEvse>();

  public locationIcon: IconName = 'directions';
  public locationIconSize: IconSize = IconSize.Default;

  public evses = toSignal(this.service.evses$);
  public loading = toSignal(this.service.loading$);

  public backAction: Action = {
    id: 'back',
    icon: 'arrow_back',
    iconSize: IconSize.Default,
    color: ActionColor.LightTransparent,
    callback: () => {
      this.back.emit();
    },
  };

  public locationEvseMessages: LocationEvseMessages = {
    ...MESSAGES.dashboardPage,
    unit: {
      kW: MESSAGES.general.unit.kW,
      perKWh: MESSAGES.general.unit.perKWh,
      perMinute: MESSAGES.general.unit.perMinute,
    },
  };

  public onDriveToLocation(location: Location): void {
    window.open(
      `https://www.google.com/maps/place/${location.coordinates?.latitude},${location.coordinates?.longitude}`,
    );
  }
}
