import { Address, Nil } from '@model';
import { LatLng } from '@model/geography';
import { isNil } from 'lodash-es';

export function getMapOptions(
  center: LatLng,
  zoom: number,
  minZoom: number,
): google.maps.MapOptions {
  return {
    center,
    zoom,
    minZoom,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: false,
    mapTypeControlOptions: {
      position: google.maps.ControlPosition.BOTTOM_CENTER,
    },
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ],
    restriction: {
      latLngBounds: {
        east: 179.9999,
        north: 85,
        south: -85,
        west: -179.9999,
      },
      strictBounds: true,
    },
  };
}

export function getLatLng(address: Address | Nil): LatLng | Nil {
  if (isNil(address) || isNil(address.latitude) || isNil(address.longitude)) {
    return undefined;
  }
  return new LatLng(address.latitude, address.longitude);
}
