import { IdJsonSchema, MultiLanguageJsonSchema } from '@api';
import { LinksJson } from '@api/hateos';
import { environment } from '@env/evd/environment';
import { LocationEvseContract } from '@model/evd/locations';
import { z } from 'zod';

export const LocationEvseContractJsonSchema = z.object({
  contractId: IdJsonSchema,
  contractName: MultiLanguageJsonSchema,
  fixedFee: z.number().optional(),
  idleFee: z.number().optional(),
  idleFeeApplicableAfterMinutes: z.number().optional(),
  pricePerKwh: z.number().optional(),
  pricePerMinute: z.number().optional(),
  currencyCode: z.string(),
  hasAuthMeanAssigned: z.boolean(),
  isEvseAvailable: z.boolean(),
  isScheduled: z.boolean(),
  _links: LinksJson(environment.urls.api).optional(),
});

export type LocationEvseContractJson = z.infer<
  typeof LocationEvseContractJsonSchema
>;

export function serializeLocationEvseContract(
  contract: LocationEvseContract,
): LocationEvseContractJson {
  return {
    ...contract,
    contractName: contract.name,
    contractId: contract.id,
  };
}

export function deserializeLocationEvseContract(
  contract: LocationEvseContractJson,
): LocationEvseContract {
  return {
    ...contract,
    id: contract.contractId,
    name: contract.contractName,
  };
}
