<etn-checkbox
  [(value)]="allSelected"
  [indeterminate]="someSelected"
  (click)="onSelectAllClick()"
>
  <strong>{{ messages.title.selectAll }}</strong>
</etn-checkbox>
<etn-list-item-separator></etn-list-item-separator>
<etn-checkbox-group
  [dense]="true"
  [display]="display"
  [divider]="false"
  [values]="values"
  [value]="value"
  (valueChange)="onValueChange($event)"
></etn-checkbox-group>
<etn-list-item-separator></etn-list-item-separator>
