import { Nil } from '@model';
import * as d3 from 'd3';

import { Marker } from '../map.types';
import { BaseElement, EnterElement, SvgElement, enterCircle } from './d3-utils';

export function drawUserPosition(
  svgElement: Element,
  position: Marker | Nil,
): void {
  d3.select(svgElement)
    .selectAll<d3.BaseType, Marker>('g.user-position')
    .data(position ? [position] : [], (datum) => {
      return datum ? `${datum.id}` : '';
    })
    .join(
      (element) => {
        return enterFunction(element);
      },
      updateFunction,
      (exit) => {
        return exit.remove();
      },
    );
}

function enterFunction(selection: EnterElement<Marker>): SvgElement<Marker> {
  const group = selection.append('g').attr('class', 'user-position');

  enterCircle<Marker>('outer', 9)(group);
  enterCircle<Marker>('inner', 7)(group);

  return group;
}

function updateFunction(selection: BaseElement<Marker>): BaseElement<Marker> {
  return selection.attr('transform', ({ point }) => {
    return `translate(${point.x} ${point.y})`;
  });
}
