import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { formatPrice } from '@core/string';
import { Nil } from '@model';
import { ListLocationEvse } from '@model/evd/locations';
import { ChannelValueComponent } from '@ui/channel-value';
import { IconComponent, IconName } from '@ui/icon';
import { LanguageService } from '@ui/language';
import { isNil } from 'lodash-es';

import { LocationEvsePricingMessages } from './location-evse-pricing.types';

@Component({
  selector: 'app-location-evse-pricing',
  standalone: true,
  imports: [CommonModule, IconComponent, ChannelValueComponent],
  templateUrl: './location-evse-pricing.component.html',
  styleUrl: './location-evse-pricing.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationEvsePricingComponent {
  public constructor(private languageService: LanguageService) {}

  public evse = input.required<ListLocationEvse>();
  public messages = input.required<LocationEvsePricingMessages>();

  public batteryIcon: IconName = 'battery_full';
  public parkingIcon: IconName = 'local_parking';

  public fixedFee = computed(() => {
    return this.getPrice(this.evse().fixedFee);
  });

  public pricePerKwh = computed(() => {
    return this.getPrice(this.evse().pricePerKwh);
  });

  public pricePerMinute = computed(() => {
    return this.getPrice(this.evse().pricePerMinute);
  });

  public idleFee = computed(() => {
    return this.getIdleFee(this.evse(), this.messages());
  });

  private getPrice(price: number | Nil): string | Nil {
    if (isNil(price)) {
      return undefined;
    }

    return formatPrice(price, this.languageService.getLanguage());
  }

  private getIdleFee(
    evse: ListLocationEvse | Nil,
    messages: LocationEvsePricingMessages | Nil,
  ): string | Nil {
    const idleFee = evse?.idleFee;
    const idleFeeApplicableAfterMinutes = evse?.idleFeeApplicableAfterMinutes;

    if (
      isNil(evse) ||
      isNil(idleFee) ||
      isNil(messages) ||
      isNil(idleFeeApplicableAfterMinutes)
    ) {
      return undefined;
    }

    return messages.value.idleFee(
      evse?.currencyCode,
      `<strong>${formatPrice(
        idleFee,
        this.languageService.getLanguage(),
      )}</strong>`,
      `<strong>${idleFeeApplicableAfterMinutes}</strong>`,
    );
  }
}
