<blui-score-card
  [headerTitle]="headerTitle ?? ''"
  [headerSubtitle]="headerSubtitle ?? ''"
  [headerInfo]="headerInfo ?? ''"
>
  <ng-container blui-action-items>
    @for (action of cardActions; track action.id) {
      <etn-action [action]="action"></etn-action>
    }
    @if (menuAction) {
      <etn-action-menu [action]="menuAction" [menu]="menu"></etn-action-menu>
    }
  </ng-container>
  <ng-container blui-body>
    <ng-content></ng-content>
  </ng-container>
  @if (rowAction) {
    <blui-info-list-item
      blui-action-row
      [hidePadding]="!rowAction.icon"
      [dense]="true"
      [chevron]="true"
      (click)="onRowActionClick()"
    >
      @if (rowAction.icon) {
        <etn-icon blui-icon [name]="rowAction.icon"></etn-icon>
      }
      <div blui-title>{{ rowAction.name }}</div>
    </blui-info-list-item>
  }
  <ng-content blui-action-row select="[etn-action-row]"></ng-content>
</blui-score-card>
