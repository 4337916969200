import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { EmspContractsApiService } from '@api/evd/emsp';
import { isError } from '@core/error';
import { filterNil } from '@core/rxjs/filter-nil';
import { Nil, getEmbedded } from '@model';
import { EmspContractStatus, ListEmspContract } from '@model/evd/emsp';
import { getEmspContractsLink } from '@model/evd/users';
import { CurrentUserService } from '@store/evd/current-user';
import { isNil } from 'lodash-es';
import { Observable, map, of, switchMap } from 'rxjs';

export const CONTRACTS_RESOLVE_PROPERTY = 'contracts';

export const dashboardPageContractsResolver: ResolveFn<
  Observable<ListEmspContract[] | Nil>
> = () => {
  const contractsApiService = inject(EmspContractsApiService);
  const currentUserService = inject(CurrentUserService);

  return currentUserService.currentUser$.pipe(
    filterNil(),
    switchMap((user) => {
      const link = getEmspContractsLink(user);

      if (isNil(link)) {
        return of(undefined);
      }

      return contractsApiService
        .list(link, {
          pageSize: 1000,
          filterBy: 'status',
          filterCriteria: EmspContractStatus.Active,
        })
        .pipe(
          map((response) => {
            if (isError(response)) {
              return undefined;
            }
            return getEmbedded(response, 'driverContracts') ?? [];
          }),
        );
    }),
  );
};
