import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { WebsocketService } from '@core/websocket';
import {
  ListLocationEvse,
  LocationEvseStatusUpdate,
} from '@model/evd/locations';
import { filter } from 'rxjs';

import {
  LocationEvseComponent,
  LocationEvseMessages,
} from '../../components/location-evse';

@Component({
  selector: 'app-location-evse-container',
  standalone: true,
  imports: [CommonModule, LocationEvseComponent],
  templateUrl: './location-evse-container.component.html',
})
export class LocationEvseContainerComponent {
  public constructor(private websocketService: WebsocketService) {}

  public evse = input.required<ListLocationEvse>();
  public messages = input.required<LocationEvseMessages>();

  private evseStatusUpdate = toSignal(
    this.websocketService
      .on<LocationEvseStatusUpdate>('NotifyUsersOnEvseStatusChanged')
      .pipe(
        filter((statusUpdate) => {
          return (
            this.evse().chargerId === statusUpdate.chargerId &&
            this.evse().evseId === statusUpdate?.evseId
          );
        }),
      ),
  );

  public updatedEvse = computed(() => {
    const evse = this.evse();
    const statusUpdate = this.evseStatusUpdate();

    if (statusUpdate) {
      return {
        ...evse,
        status: statusUpdate.status,
      };
    }

    return evse;
  });
}
