import { isNotNil } from '@core/is-not-nil';
import { ConnectorType, EvseStatus, Id, WithId, WithLinks } from '@model';

import {
  LocationEvseContract,
  hasTransactionStartLink,
} from './location-evse-contract';

export interface LocationEvse extends WithId, WithLinks {
  chargerId: Id;
  chargerName?: string;
  chargerSerialNumber?: string;
  connectorTypes: ConnectorType[];
  evseId: Id;
  maxKw?: number;
  status: EvseStatus;
  contracts: LocationEvseContract[];
}

export interface ListLocationEvse extends WithId, WithLinks {
  chargerId: Id;
  chargerName?: string;
  chargerSerialNumber?: string;
  connectorTypes: ConnectorType[];
  evseId: Id;
  fixedFee?: number;
  idleFee?: number;
  maxKw?: number;
  pricePerKwh?: number;
  pricePerMinute?: number;
  status: EvseStatus;
  idleFeeApplicableAfterMinutes?: number;
  hasMultiplePricings?: boolean;
  currencyCode: string;
}

export function getLocationEvseDisplayName(
  evse: ListLocationEvse | LocationEvse,
): string {
  if (isNotNil(evse.chargerName)) {
    return `${evse.chargerName} ${evse.evseId}`;
  }

  if (isNotNil(evse.chargerSerialNumber)) {
    return `${evse.chargerSerialNumber} ${evse.evseId}`;
  }

  return `${evse.chargerId} ${evse.evseId}`;
}

export function getLocationEvseEnabledContracts(
  evse: LocationEvse,
): LocationEvseContract[] {
  return evse.contracts.filter(hasTransactionStartLink);
}
