"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.purple = exports.lightBlue = exports.green = exports.yellow = exports.gold = exports.orange = exports.red = exports.vantaBlack = exports.darkBlack = exports.black = exports.gray = exports.white = exports.blue = void 0;
/* Primary Colors / User Interface Colors */
exports.blue = {
  50: '#e0eff8',
  100: '#b3d7ec',
  200: '#80bde0',
  300: '#4da3d4',
  400: '#268fca',
  500: '#007bc1',
  600: '#0073bb',
  700: '#0068b3',
  800: '#005eab',
  900: '#004b9e',
  contrastDefaultColor: 'light'
};
exports.white = {
  50: '#ffffff',
  100: '#fbfbfb',
  200: '#f7f8f8',
  300: '#f3f5f5',
  400: '#f1f2f2',
  500: '#eef0f0',
  600: '#eceeee',
  700: '#e9ecec',
  800: '#e7e9e9',
  900: '#e2e5e5',
  contrastDefaultColor: 'dark'
};
exports.gray = {
  50: '#eef0f0',
  100: '#d5d8da',
  200: '#b9bfc2',
  300: '#9ca5a9',
  400: '#879196',
  500: '#727e84',
  600: '#6a767c',
  700: '#5f6b71',
  800: '#556167',
  900: '#424e54',
  contrastDefaultColor: 'light'
};
exports.black = {
  50: '#e8eaea',
  100: '#c6cacc',
  200: '#a1a7aa',
  300: '#7b8387',
  400: '#5e696e',
  500: '#424e54',
  600: '#3c474d',
  700: '#333d43',
  800: '#2b353a',
  900: '#1d2529',
  contrastDefaultColor: 'light'
};
exports.darkBlack = {
  50: '#202224',
  100: '#182022',
  200: '#182022',
  300: '#13181b',
  400: '#101417',
  500: '#0b0e10',
  600: '#0b0e10',
  700: '#08090a',
  800: '#08090a',
  900: '#000000',
  contrastDefaultColor: 'light'
};
// alias
exports.vantaBlack = exports.darkBlack;
/* Status Colors */
exports.red = {
  50: '#f9e8e8',
  100: '#efc5c5',
  200: '#e59e9e',
  300: '#da7777',
  400: '#d2595a',
  500: '#ca3c3d',
  600: '#c53637',
  700: '#bd2e2f',
  800: '#b72727',
  900: '#ab1a1a',
  contrastDefaultColor: 'light'
};
exports.orange = {
  50: '#feefe4',
  100: '#fcd6bc',
  200: '#fabb90',
  300: '#f7a064',
  400: '#f68b42',
  500: '#f47721',
  600: '#f36f1d',
  700: '#f16418',
  800: '#ef5a14',
  900: '#ec470b',
  contrastDefaultColor: 'light'
};
exports.gold = {
  50: '#fdf5e4',
  100: '#fbe6bc',
  200: '#f8d58f',
  300: '#f5c462',
  400: '#f2b741',
  500: '#f0aa1f',
  600: '#eea31b',
  700: '#ec9917',
  800: '#e99012',
  900: '#e57f0a',
  contrastDefaultColor: 'dark'
};
exports.yellow = {
  50: '#fdf9e6',
  100: '#fbefc1',
  200: '#f8e597',
  300: '#f5db6d',
  400: '#f2d34e',
  500: '#f0cb2f',
  600: '#eec62a',
  700: '#ecbe23',
  800: '#e9b81d',
  900: '#e5ac12',
  contrastDefaultColor: 'dark'
};
exports.green = {
  50: '#e7f6e4',
  100: '#c4e9bc',
  200: '#9cdb90',
  300: '#74cc63',
  400: '#57c141',
  500: '#39b620',
  600: '#33af1c',
  700: '#2ca618',
  800: '#249e13',
  900: '#178e0b',
  contrastDefaultColor: 'light'
};
exports.lightBlue = {
  50: '#e0f1fd',
  100: '#b3dbfb',
  200: '#80c4f9',
  300: '#4dacf6',
  400: '#269af4',
  500: '#0088f2',
  600: '#0080f0',
  700: '#0075ee',
  800: '#006bec',
  900: '#0058e8',
  contrastDefaultColor: 'dark'
};
exports.purple = {
  50: '#f3e8fd',
  100: '#e0c5fa',
  200: '#cc9ff7',
  300: '#b779f4',
  400: '#a75cf1',
  500: '#983fef',
  600: '#9039ed',
  700: '#8531eb',
  800: '#7b29e8',
  900: '#6a1be4',
  contrastDefaultColor: 'light'
};