<etn-score-card
  [headerTitle]="location().name"
  [headerSubtitle]="location().formattedAddress"
>
  <etn-list-item
    [chevronIcon]="undefined"
    [icon]="evStationIcon"
    [bottomDivider]="false"
  >
    <span etn-title>{{ evses() }}</span>
  </etn-list-item>
  <etn-list-item etn-action-row [dense]="true" (click)="view.emit()">
    <div etn-title class="connectors">
      @for (connector of connectors(); track connector) {
        <div class="connector">
          <etn-icon
            [name]="connectorIcons[connector]"
            [color]="iconColor"
          ></etn-icon>
          @if (location().connectorTypes[connector]; as connectorCount) {
            <span>{{ messages().value.connectorCount(connectorCount) }}</span>
          }
        </div>
      }
      @if (hasMoreConnectors()) {
        <span>...</span>
      }
    </div>
    <div etn-right-content>
      @if (minMaxKw(); as minMaxKw) {
        <etn-channel-value
          [value]="minMaxKw"
          [units]="messages().unit.kW"
        ></etn-channel-value>
      }
    </div>
  </etn-list-item>
</etn-score-card>
