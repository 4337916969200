<div class="row">
  <etn-icon [name]="batteryIcon"></etn-icon>
  <div>
    @if (fixedFee(); as fixedFee) {
      <etn-channel-value
        [value]="fixedFee"
        [units]="evse().currencyCode"
        [prefix]="true"
      ></etn-channel-value>
      @if (pricePerKwh() || pricePerMinute()) {
        <span class="separator">+</span>
      }
    }
    @if (pricePerKwh(); as pricePerKwh) {
      <span class="currency">{{ evse().currencyCode }}&nbsp;</span>
      <etn-channel-value
        [value]="pricePerKwh"
        [units]="messages().unit.perKWh"
      ></etn-channel-value>
      @if (pricePerMinute()) {
        <span class="separator">+</span>
      }
    }
    @if (pricePerMinute(); as pricePerMinute) {
      <span class="currency">{{ evse().currencyCode }}&nbsp;</span>
      <etn-channel-value
        [value]="pricePerMinute"
        [units]="messages().unit.perMinute"
      ></etn-channel-value>
    }
    @if (evse().hasMultiplePricings) {
      <span class="multiple-pricing">{{
        messages().value.multiplePricing
      }}</span>
    }
  </div>
</div>

@if (idleFee(); as idleFee) {
  <div class="row idle-fee">
    <etn-icon [name]="parkingIcon"></etn-icon>
    <span [innerHTML]="idleFee"></span>
  </div>
}
