import { gray, green, lightBlue, orange, red } from '@brightlayer-ui/colors';
import { BLUIColor } from '@brightlayer-ui/types';
import { MESSAGES } from '@i18n/evd';
import { EvseStatus } from '@model';
import { FabIconColor } from '@ui/fab-icon';
import { IconColor, IconName } from '@ui/icon';
import { MapDataSourceItemStatus } from '@ui/map';

export const EVSE_STATUS_ICONS: Record<EvseStatus, IconName> = {
  [EvseStatus.Available]: 'check_circle',
  [EvseStatus.Charging]: 'bolt',
  [EvseStatus.Faulted]: 'error',
  [EvseStatus.Finishing]: 'blui-stopping',
  [EvseStatus.Preparing]: 'restart_alt',
  [EvseStatus.Reserved]: 'event_available',
  [EvseStatus.SuspendedEv]: 'pause_circle',
  [EvseStatus.SuspendedEvse]: 'pause_circle',
  [EvseStatus.Unavailable]: 'blui-maintenance',
  [EvseStatus.Unknown]: 'help',
};

export const EVSE_STATUS_COLORS: Record<EvseStatus, BLUIColor> = {
  [EvseStatus.Available]: green,
  [EvseStatus.Charging]: lightBlue,
  [EvseStatus.Faulted]: red,
  [EvseStatus.Finishing]: lightBlue,
  [EvseStatus.Preparing]: lightBlue,
  [EvseStatus.Reserved]: gray,
  [EvseStatus.SuspendedEv]: lightBlue,
  [EvseStatus.SuspendedEvse]: lightBlue,
  [EvseStatus.Unavailable]: orange,
  [EvseStatus.Unknown]: gray,
};

export const EVSE_STATUS_ICON_COLORS: Record<EvseStatus, IconColor> = {
  [EvseStatus.Available]: IconColor.Green,
  [EvseStatus.Charging]: IconColor.LightBlue,
  [EvseStatus.Faulted]: IconColor.Red,
  [EvseStatus.Finishing]: IconColor.LightBlue,
  [EvseStatus.Preparing]: IconColor.LightBlue,
  [EvseStatus.Reserved]: IconColor.Grey,
  [EvseStatus.SuspendedEv]: IconColor.LightBlue,
  [EvseStatus.SuspendedEvse]: IconColor.LightBlue,
  [EvseStatus.Unavailable]: IconColor.Orange,
  [EvseStatus.Unknown]: IconColor.Grey,
};

export const EVSE_STATUS_MAP_STATUS: Record<
  EvseStatus,
  MapDataSourceItemStatus
> = {
  [EvseStatus.Available]: MapDataSourceItemStatus.Online,
  [EvseStatus.Charging]: MapDataSourceItemStatus.Charging,
  [EvseStatus.Faulted]: MapDataSourceItemStatus.Error,
  [EvseStatus.Finishing]: MapDataSourceItemStatus.Charging,
  [EvseStatus.Preparing]: MapDataSourceItemStatus.Charging,
  [EvseStatus.Reserved]: MapDataSourceItemStatus.Offline,
  [EvseStatus.SuspendedEv]: MapDataSourceItemStatus.Charging,
  [EvseStatus.SuspendedEvse]: MapDataSourceItemStatus.Charging,
  [EvseStatus.Unavailable]: MapDataSourceItemStatus.Warning,
  [EvseStatus.Unknown]: MapDataSourceItemStatus.Offline,
};

export const EVSE_STATUS_FAB_COLORS: Record<EvseStatus, FabIconColor> = {
  [EvseStatus.Available]: FabIconColor.Green,
  [EvseStatus.Charging]: FabIconColor.LightBlue,
  [EvseStatus.Faulted]: FabIconColor.Warn,
  [EvseStatus.Finishing]: FabIconColor.LightBlue,
  [EvseStatus.Preparing]: FabIconColor.LightBlue,
  [EvseStatus.Reserved]: FabIconColor.Grey,
  [EvseStatus.SuspendedEv]: FabIconColor.LightBlue,
  [EvseStatus.SuspendedEvse]: FabIconColor.LightBlue,
  [EvseStatus.Unavailable]: FabIconColor.Orange,
  [EvseStatus.Unknown]: FabIconColor.Grey,
};

export const EVSE_STATUS_TRANSLATIONS: Record<EvseStatus, string> = {
  [EvseStatus.Available]: MESSAGES.general.evseStatus.available,
  [EvseStatus.Charging]: MESSAGES.general.evseStatus.charging,
  [EvseStatus.Faulted]: MESSAGES.general.evseStatus.faulted,
  [EvseStatus.Finishing]: MESSAGES.general.evseStatus.finishing,
  [EvseStatus.Preparing]: MESSAGES.general.evseStatus.preparing,
  [EvseStatus.Reserved]: MESSAGES.general.evseStatus.reserved,
  [EvseStatus.SuspendedEv]: MESSAGES.general.evseStatus.suspendedEv,
  [EvseStatus.SuspendedEvse]: MESSAGES.general.evseStatus.suspendedEvse,
  [EvseStatus.Unavailable]: MESSAGES.general.evseStatus.unavailable,
  [EvseStatus.Unknown]: MESSAGES.general.evseStatus.unknown,
};
