import {
  ConnectorType,
  Link,
  WithId,
  WithLinks,
  WithName,
  getMandatoryRelation,
} from '@model';
import { WithCoordinates } from '@model/with-coordinates';

import { LocationEvse } from './location-evse';

export const COLLECTION_LOCATION_EVSES_RELATION = 'collection:evses';

export interface Location extends WithId, WithName, WithCoordinates, WithLinks {
  formattedAddress: string;
  connectorTypes: Partial<Record<ConnectorType, number>>;
  minKw: number;
  maxKw: number;
  evses: Pick<LocationEvse, 'id' | 'evseId' | 'status'>[];
}

export function getLocationEvsesLink(location: Location): Link {
  return getMandatoryRelation(location, COLLECTION_LOCATION_EVSES_RELATION);
}
