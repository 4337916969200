<div class="locations" [ngStyle]="locationsStyles()">
  @for (location of locations(); let i = $index; track location.id) {
    <app-location-card
      [userLatLng]="userLatLng()"
      [location]="location"
      [messages]="messages()"
      [ngStyle]="locationStyles()"
      (view)="view.emit(location)"
    ></app-location-card>
  }
</div>
@if (!isVertical()) {
  <etn-mobile-stepper
    [steps]="locations().length"
    [activeStep]="currentCardIndex()"
  ></etn-mobile-stepper>
}
