<div appSubtitle1>{{ name() }}</div>
<app-location-evse-connectors
  [evse]="evse()"
  [messages]="messages()"
></app-location-evse-connectors>
<div class="pricing">
  <app-location-evse-pricing
    [evse]="evse()"
    [messages]="messages()"
  ></app-location-evse-pricing>
  <etn-icon [name]="chevronIcon"></etn-icon>
</div>
