import {
  AbstractHateoasApiService,
  PaginatedResults,
  isPaginatedResults,
} from '@api/hateos';
import { isError } from '@core/error';
import { calculateDistanceInMeters } from '@core/geography';
import { isNotNil } from '@core/is-not-nil';
import { Link, Nil, WithId, WithName, getEmbedded } from '@model';
import { LatLng } from '@model/geography';
import { WithCoordinates } from '@model/with-coordinates';
import {
  MapDataSource,
  MapDataSourceItem,
  MapDataSourceItemStatus,
} from '@ui/map';
import { isNil } from 'lodash-es';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  delay,
  map,
  of,
  scan,
  shareReplay,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

export interface MapDataSourceWithDistanceConfig<T> {
  link: Link;
  api: AbstractHateoasApiService;
  embedded: string;
  counter: (item: T, status: MapDataSourceItemStatus) => number;
  paginated: boolean;
  refresh?: Observable<void>;
}

export class MapContainerDataSourceWithDistance<
  T extends WithId & WithCoordinates & WithName,
> {
  public constructor(private config: MapDataSourceWithDistanceConfig<T>) {}

  private pageSubject$ = new BehaviorSubject<number>(1);
  private pageSize = 10;

  private userPosition$ = new BehaviorSubject<LatLng | Nil>(undefined);

  private dataSource$ = combineLatest([
    this.pageSubject$,
    this.config.refresh?.pipe(startWith(undefined)) ?? of(undefined),
  ]).pipe(
    switchMap(([page]) => {
      if (this.config.paginated) {
        return this.config.api.list(this.config.link, {
          page,
          pageSize: this.pageSize,
        });
      }
      return this.config.api.listWithoutPagination(
        this.config.link,
        'locations',
        {
          page,
          pageSize: this.pageSize,
        },
      );
    }),
    shareReplay(1),
  );

  public allDataSource$: Observable<MapDataSource<T>> = this.dataSource$.pipe(
    withLatestFrom(this.pageSubject$),
    tap(([data, page]) => {
      if (!isError(data)) {
        if (isPaginatedResults(data)) {
          // fetch next page if we haven't reach max page and no error occured
          if (page < Math.ceil(data.total / this.pageSize)) {
            this.pageSubject$.next(page + 1);
          }
        }
      }
    }),
    map(([data]) => {
      return this.getItems(data);
    }),
    scan((acc, value) => {
      if (isNil(this.config.refresh)) {
        return [...acc, ...value];
      }
      return value;
    }),
    map((items) => {
      return {
        items,
      };
    }),
    shareReplay(1),
  );

  public mapDataSource$: Observable<MapDataSource<T>> = combineLatest([
    this.allDataSource$,
    this.userPosition$,
  ]).pipe(
    map(([data, userPosition]) => {
      if (isNotNil(userPosition)) {
        return {
          ...data,
          items: data.items.sort((a, b) => {
            const aDistance = calculateDistanceInMeters(
              userPosition,
              new LatLng(a.latLng),
            );
            const bDistance = calculateDistanceInMeters(
              userPosition,
              new LatLng(b.latLng),
            );

            return aDistance > bDistance ? 1 : -1;
          }),
        };
      }

      return data;
    }),
    shareReplay(1),
  );

  public ready$: Observable<boolean> = this.dataSource$.pipe(
    withLatestFrom(this.pageSubject$),
    switchMap(([data, page]) => {
      const ready = this.isReady(data, page);
      if (ready) {
        return of(ready).pipe(delay(500));
      }
      return of(ready);
    }),
    startWith(false),
  );

  public empty$ = this.dataSource$.pipe(
    map((data) => {
      return this.isEmpty(data);
    }),
  );

  public setUserPosition(userPosition: LatLng | Nil): void {
    this.userPosition$.next(userPosition);
  }

  private getItems(
    results: PaginatedResults<T> | T[] | Error,
  ): MapDataSourceItem<T>[] {
    if (isError(results)) {
      return [];
    }

    const embedded = isPaginatedResults(results)
      ? (getEmbedded(results, this.config.embedded) ?? [])
      : results;
    return embedded
      .map((item) => {
        return this.getMapDataSourceItem(item);
      })
      .filter(isNotNil);
  }

  private getMapDataSourceItem(item: T): MapDataSourceItem<T> | Nil {
    if (isNil(item.coordinates)) {
      return undefined;
    }

    if (item.coordinates.latitude === 0 && item.coordinates.longitude === 0) {
      return undefined;
    }

    return {
      id: item.id,
      data: item,
      latLng: new LatLng(item.coordinates.latitude, item.coordinates.longitude),
      statuses: {
        [MapDataSourceItemStatus.Charging]: this.config.counter(
          item,
          MapDataSourceItemStatus.Charging,
        ),
        [MapDataSourceItemStatus.Error]: this.config.counter(
          item,
          MapDataSourceItemStatus.Error,
        ),
        [MapDataSourceItemStatus.Offline]: this.config.counter(
          item,
          MapDataSourceItemStatus.Offline,
        ),
        [MapDataSourceItemStatus.Online]: this.config.counter(
          item,
          MapDataSourceItemStatus.Online,
        ),
        [MapDataSourceItemStatus.Warning]: this.config.counter(
          item,
          MapDataSourceItemStatus.Warning,
        ),
      },
      label: item.name,
    };
  }

  private isReady(
    data: T[] | PaginatedResults<T> | Error,
    page: number,
  ): boolean {
    if (isError(data)) {
      return false;
    }

    if (isPaginatedResults(data)) {
      return data.total === 0 || page === Math.ceil(data.total / this.pageSize);
    }

    return true;
  }

  private isEmpty(data: PaginatedResults<T> | T[] | Error): boolean {
    if (isError(data)) {
      return true;
    }

    if (isPaginatedResults(data)) {
      return data.total === 0;
    }

    return data.length === 0;
  }
}
