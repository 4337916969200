import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {
  DEFAULT_LAT_LNG,
  getCurrentUserLatLng,
  watchUserLatLng,
} from '@core/geography';
import { isNotNil } from '@core/is-not-nil';
import { Address, Nil } from '@model';
import { LatLng } from '@model/geography';
import { Action, ActionComponent } from '@ui/action';
import { CardComponent } from '@ui/card';
import { ListItemComponent } from '@ui/list-item';
import {
  AutoFitBounds,
  BoundsChangeEvent,
  MapComponent,
  MapDataSourceItemData,
} from '@ui/map';
import { ProgressSpinnerComponent } from '@ui/progress-spinner';
import { NgLetModule } from 'ng-let';
import { ReplaySubject } from 'rxjs';

import { MapContainerDataSource } from './map-container.datasource';
import { MapContainerDataSourceWithBounds } from './map-container.datasource-with-bounds';
import { MapContainerDataSourceWithDistance } from './map-container.datasource-with-distance';
import { MapContainerMessages } from './map-container.types';
import { UserPositionActionComponent } from './user-position-action/user-position-action.component';

@Component({
  selector: 'etn-map-container',
  templateUrl: './map-container.component.html',
  styleUrls: ['./map-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ActionComponent,
    CardComponent,
    CommonModule,
    ListItemComponent,
    MapComponent,
    NgLetModule,
    ProgressSpinnerComponent,
    UserPositionActionComponent,
  ],
})
export class MapContainerComponent implements OnChanges, OnInit {
  @Input() public dataSource:
    | MapContainerDataSource<any>
    | MapContainerDataSourceWithBounds<any>
    | MapContainerDataSourceWithDistance<any>
    | Nil;
  @Input() public search = false;
  @Input() public messages: MapContainerMessages | Nil;
  @Input() public enableUserPosition = false;
  @Input() public zoom = 21;
  @Input() public minZoom: number | Nil;
  @Input() public autoFitBounds: AutoFitBounds = AutoFitBounds.Always;
  @Input() public actions: Action[] | Nil;
  @Input() public editable = true;

  @Output() public itemSelect = new EventEmitter<MapDataSourceItemData | Nil>();
  @Output() public centerChange = new EventEmitter<LatLng>();
  @Output() public addressChange = new EventEmitter<Address | Nil>();

  @ViewChildren('action', { read: ElementRef }) public set actionsRef(
    actionsRef: QueryList<ElementRef>,
  ) {
    this._actionsRef = actionsRef;
    this.updateControls(this._map, actionsRef);
  }

  private _actionsRef: QueryList<ElementRef> | Nil;
  private _map: google.maps.Map | Nil;

  public userPosition$ = new ReplaySubject<LatLng | Nil>(1);

  public center$ = new ReplaySubject<LatLng>(1);

  public ngOnInit(): void {
    this.updateUserPosition();
    watchUserLatLng((latLng) => {
      if (this.enableUserPosition) {
        this.userPosition$.next(latLng);
        if (
          isNotNil(this.dataSource) &&
          this.dataSource instanceof MapContainerDataSourceWithDistance
        ) {
          this.dataSource.setUserPosition(latLng);
        }
      }
    });
  }

  public ngOnChanges({ enableUserPosition }: SimpleChanges): void {
    if (isNotNil(enableUserPosition)) {
      this.updateUserPosition();
    }
  }

  public onReady(map: google.maps.Map): void {
    this._map = map;
    this.updateControls(this._map, this._actionsRef);
  }

  public onBoundsChanged(event: BoundsChangeEvent): void {
    if (
      isNotNil(this.dataSource) &&
      this.dataSource instanceof MapContainerDataSourceWithBounds &&
      isNotNil(event.bounds)
    ) {
      this.dataSource.setBounds(event.bounds, event.userPosition);
    }
  }

  public onValueChange(address: Address | Nil): void {
    this.addressChange.emit(address);
  }

  public onUserPositionClick(): void {
    this.updateUserPosition();
  }

  public setCenter(latLng: LatLng, zoom?: number): void {
    this._map?.setCenter(latLng);
    this._map?.setZoom(zoom ?? this.zoom);
  }

  private updateUserPosition(): void {
    getCurrentUserLatLng((latLng) => {
      this.center$.next(latLng ?? DEFAULT_LAT_LNG);
      this._map?.setZoom(this.zoom);
      this.updateControls(this._map, this._actionsRef);
    });
  }

  private updateControls(
    map: google.maps.Map | Nil,
    actionsRef: QueryList<ElementRef> | Nil,
  ): void {
    if (isNotNil(actionsRef) && isNotNil(map)) {
      const controls = map.controls[google.maps.ControlPosition.RIGHT_BOTTOM];

      controls.clear();

      actionsRef.forEach((actionRef) => {
        controls.push(actionRef.nativeElement);
        actionRef.nativeElement.style.display = 'block';
      });
    }
  }
}
