<div appPrimarySubtitle2>{{ messages.title.locations }}</div>
<etn-list-item-separator></etn-list-item-separator>
@if (carouselDirection(); as direction) {
  <app-location-card-carousel
    [locations]="locations()"
    [messages]="locationCardMessages"
    [userLatLng]="userLatLng()"
    [direction]="direction"
    (view)="locationView.emit($event)"
    (swipe)="locationSwipe.emit($event)"
  ></app-location-card-carousel>
}
