import { LatLng, Point } from '@model/geography';
import * as d3 from 'd3';
import { sum } from 'lodash-es';
import * as Supercluster from 'supercluster';
import { ClusterProperties } from 'supercluster';

import {
  Cluster,
  MapDataSourceItem,
  MapDataSourceItemData,
  MapDataSourceItemStatus,
  SuperClusterFeature,
  isCluster,
  isMarker,
} from '../map.types';
import {
  BaseElement,
  EnterElement,
  SvgElement,
  enterCircle,
  enterClusterPieChart,
  enterCounter,
} from '../utils/d3-utils';

export function getClusters(
  features: SuperClusterFeature[],
  projection: google.maps.MapCanvasProjection,
  superCluster: Supercluster<
    MapDataSourceItem<MapDataSourceItemData>,
    ClusterProperties
  >,
): Cluster[] {
  return features.filter(isCluster).map((feature) => {
    const statuses = getStatusCount(
      feature.properties.cluster_id,
      superCluster,
    );
    const [lng, lat] = feature.geometry.coordinates;
    const latLng = new LatLng(lat, lng);
    return {
      clusterId: feature.properties.cluster_id,
      latLng,
      point: projection.fromLatLngToDivPixel(latLng) ?? new Point(0, 0),
      count: sum(Object.values(statuses)),
      statuses,
    };
  });
}

export function drawClusters(
  svgElement: Element,
  clusters: Cluster[],
  callback: (cluster: Cluster) => void,
): void {
  d3.select(svgElement)
    .selectAll<d3.BaseType, Cluster>('g.cluster')
    .data(clusters, ({ clusterId }) => {
      return `${clusterId}`;
    })
    .join(enterFunction, updateFunction, (exit) => {
      return exit.remove();
    })
    .on('click', (_cluster, datum) => {
      return callback(datum);
    });
}

function enterFunction(selection: EnterElement<Cluster>): SvgElement<Cluster> {
  const group = selection.append('g').attr('class', 'cluster');

  enterCircle<Cluster>('outer-20', 23)(group);
  enterCircle<Cluster>('outer-35', 20)(group);
  enterCircle<Cluster>('inner', 11)(group);
  enterCounter<Cluster>()(group);
  enterClusterPieChart('pie', 11, 15)(group);

  return group;
}

function updateFunction(selection: BaseElement<Cluster>): BaseElement<Cluster> {
  return selection.attr('transform', ({ point }) => {
    return `translate(${point.x} ${point.y})`;
  });
}

function getStatusCount(
  clusterId: number,
  superCluster: Supercluster<
    MapDataSourceItem<MapDataSourceItemData>,
    ClusterProperties
  >,
): Record<string, number> {
  const leaves = superCluster
    .getLeaves(clusterId, Infinity)
    .filter(isMarker)
    .map(({ properties }) => {
      return properties.statuses;
    });

  const sums: Record<string, number> = {};

  leaves.forEach((leaf) => {
    for (const status of Object.keys(leaf)) {
      const count = sums[status] || 0;
      sums[status] = count + leaf[status as MapDataSourceItemStatus];
    }
  });

  return sums;
}
