<form [formGroup]="checkboxFormGroup">
  <div formArrayName="options" class="checkbox-container">
    @if (display === verticalDisplay) {
      @for (
        control of options.controls;
        track i;
        let i = $index;
        let isLast = $last
      ) {
        <blui-info-list-item
          [dense]="dense"
          [divider]="divider && (!isLast || lastDivider) ? 'full' : undefined"
          class="checkbox"
        >
          <mat-checkbox
            blui-icon
            [formControl]="control"
            [attr.data-test]="controlName + '-checkbox-' + optionsValues[i].id"
            (change)="onCheckboxChange()"
            (click)="onCheckboxClick()"
          ></mat-checkbox>
          <div blui-title class="title">
            @if (optionsValues[i].icon) {
              <etn-icon [name]="optionsValues[i].icon"></etn-icon>
            }
            <span>{{ optionsValues[i].name }}</span>
          </div>
          <span blui-subtitle>{{ optionsValues[i].description }}</span>
        </blui-info-list-item>
      }
    } @else {
      @for (control of options.controls; track i; let i = $index) {
        <div class="checkbox">
          <mat-checkbox
            [formControl]="control"
            [attr.data-test]="controlName + '-checkbox-' + optionsValues[i].id"
            (change)="onCheckboxChange()"
            (click)="onCheckboxClick()"
          >
            @if (optionsValues[i].icon) {
              <etn-icon [name]="optionsValues[i].icon"></etn-icon>
            }
            <span>{{ optionsValues[i].name }}</span>
          </mat-checkbox>
        </div>
      }
    }
  </div>
</form>
