import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Link } from '@model';
import { Location } from '@model/evd/locations';
import { z } from 'zod';

import { AbstractEvdApiService } from '../evd-api.service';
import {
  LocationJson,
  LocationJsonSchema,
  deserializeLocation,
  serializeLocation,
} from './io/location.io';

@Injectable({
  providedIn: 'root',
})
export class LocationsApiService extends AbstractEvdApiService<
  Location,
  LocationJson
> {
  public constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  protected serialize(location: Location): LocationJson {
    return serializeLocation(location);
  }

  protected deserialize(location: LocationJson): Location {
    return deserializeLocation(location);
  }

  protected getLinksLink(): Link {
    throw new Error('Not available');
  }

  protected getJsonSchema(): z.Schema<LocationJson> {
    return LocationJsonSchema;
  }

  protected getListJsonSchema(): z.Schema<LocationJson> {
    return this.getJsonSchema();
  }

  protected listDeserialize(item: LocationJson): Location {
    return this.deserialize(item);
  }
}
