import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  computed,
  input,
  output,
} from '@angular/core';
import { CONNECTOR_TYPE_ICON } from '@core/evd/utils';
import { formatPower } from '@core/string';
import { getPlural } from '@i18n/evd';
import { ConnectorType, Nil } from '@model';
import { Location } from '@model/evd/locations';
import { LatLng } from '@model/geography';
import { ChannelValueComponent } from '@ui/channel-value';
import { Icon, IconColor, IconComponent } from '@ui/icon';
import { LanguageService } from '@ui/language';
import { ListItemComponent } from '@ui/list-item';
import { ScoreCardComponent } from '@ui/score-card';
import { isNil, keys } from 'lodash-es';

import { LocationCardMessages } from './location-card.types';

const MAX_DISPLAYED_CONNECTORS = 3;

@Component({
  selector: 'app-location-card',
  standalone: true,
  imports: [
    CommonModule,
    ScoreCardComponent,
    ListItemComponent,
    ChannelValueComponent,
    IconComponent,
  ],
  templateUrl: './location-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './location-card.component.scss',
})
export class LocationCardComponent {
  public constructor(private languageService: LanguageService) {}

  public userLatLng = input<LatLng | Nil>();
  public location = input.required<Location>();
  public messages = input.required<LocationCardMessages>();

  public view = output<void>();

  public evStationIcon: Icon = {
    name: 'ev_station',
  };

  public iconColor: IconColor = IconColor.Grey;

  public minMaxKw: Signal<string | Nil> = computed(() => {
    if (this.location().minKw === 0 && this.location().maxKw === 0) {
      return undefined;
    }

    return this.messages().value.minMaxKw(
      formatPower(this.location().minKw, this.languageService.getLanguage()),
      formatPower(this.location().maxKw, this.languageService.getLanguage()),
    );
  });

  public evses: Signal<string | Nil> = computed(() => {
    return this.getEvses(this.location(), this.messages());
  });

  public connectors: Signal<ConnectorType[]> = computed(() => {
    const connectors = keys(this.location().connectorTypes) as ConnectorType[];

    if (connectors.length <= MAX_DISPLAYED_CONNECTORS) {
      return connectors;
    }

    return connectors.slice(0, MAX_DISPLAYED_CONNECTORS);
  });

  public hasMoreConnectors: Signal<boolean> = computed(() => {
    return (
      keys(this.location().connectorTypes).length > MAX_DISPLAYED_CONNECTORS
    );
  });

  public connectorIcons = CONNECTOR_TYPE_ICON;

  private getEvses(
    location: Location | Nil,
    messages: LocationCardMessages | Nil,
  ): string | Nil {
    if (isNil(location) || isNil(messages)) {
      return undefined;
    }
    return getPlural(messages.value.evses, location.evses.length);
  }
}
