import {
  AbstractHateoasApiService,
  ListParameters,
  PaginatedResults,
} from '@api/hateos';
import { isError } from '@core/error';
import { isNotNil } from '@core/is-not-nil';
import { Link, Nil, WithId, WithName, getEmbedded } from '@model';
import { Bounds, LatLng } from '@model/geography';
import { WithCoordinates } from '@model/with-coordinates';
import {
  MapDataSource,
  MapDataSourceItem,
  MapDataSourceItemStatus,
} from '@ui/map';
import { isNil } from 'lodash-es';
import {
  Observable,
  ReplaySubject,
  debounceTime,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

export interface MapDataSourceWithBoundsConfig<T> {
  link: Link;
  api: AbstractHateoasApiService;
  embedded: string;
  statuses: (item: T) => Record<MapDataSourceItemStatus, number>;
}

export class MapContainerDataSourceWithBounds<
  T extends WithId & WithCoordinates & WithName,
> {
  public constructor(private config: MapDataSourceWithBoundsConfig<T>) {}

  private boundsSubject$ = new ReplaySubject<{
    bounds: Bounds;
    userPosition: LatLng | Nil;
  }>(1);

  public loading$ = new ReplaySubject<boolean>(1);

  private dataSource$ = this.boundsSubject$.pipe(
    debounceTime(500),
    tap(() => {
      this.loading$.next(true);
    }),
    switchMap(({ bounds, userPosition }) => {
      const params: ListParameters<any> = {
        swLongitude: bounds.getSouthWest().lng(),
        swLatitude: bounds.getSouthWest().lat(),
        neLongitude: bounds.getNorthEast().lng(),
        neLatitude: bounds.getNorthEast().lat(),
      };

      if (isNotNil(userPosition)) {
        params.referenceLongitude = userPosition?.lng();
        params.referenceLatitude = userPosition?.lat();
      }

      return this.config.api.list(this.config.link, params);
    }),
    tap(() => {
      this.loading$.next(false);
    }),
    shareReplay(1),
  );

  public mapDataSource$: Observable<MapDataSource<T>> = this.dataSource$.pipe(
    map((data) => {
      return {
        items: this.getItems(data),
      };
    }),
    shareReplay(1),
  );

  public ready$: Observable<boolean> = this.dataSource$.pipe(
    switchMap((data) => {
      return of(!isError(data));
    }),
    startWith(false),
    shareReplay(1),
  );

  public empty$ = this.dataSource$.pipe(
    map((data) => {
      return !isError(data) && data.total === 0;
    }),
  );

  public setBounds(bounds: Bounds, userPosition: LatLng | Nil): void {
    this.boundsSubject$.next({ bounds, userPosition });
  }

  private getItems(
    results: PaginatedResults<T> | Error,
  ): MapDataSourceItem<T>[] {
    if (isError(results)) {
      return [];
    }
    const embedded = getEmbedded(results, this.config.embedded) ?? [];
    return embedded
      .map((item) => {
        return this.getMapDataSourceItem(item);
      })
      .filter(isNotNil);
  }

  private getMapDataSourceItem(item: T): MapDataSourceItem<T> | Nil {
    if (isNil(item.coordinates)) {
      return undefined;
    }

    if (item.coordinates.latitude === 0 && item.coordinates.longitude === 0) {
      return undefined;
    }

    return {
      id: item.id,
      data: item,
      latLng: new LatLng(item.coordinates.latitude, item.coordinates.longitude),
      statuses: this.config.statuses(item),
      label: item.name,
    };
  }
}
