import { ResolveFn } from '@angular/router';
import { getCurrentUserLatLng } from '@core/geography';
import { Nil } from '@model';
import { LatLng } from '@model/geography';
import { Observable, bindCallback } from 'rxjs';

export const USER_LAT_LNG_RESOVE_PROPERTY = 'user-lat-lng';

export const dashboardPageResolver: ResolveFn<
  Observable<LatLng | Nil>
> = () => {
  return bindCallback(getCurrentUserLatLng)();
};
